var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "mt-4" }, [
      _c("h4", [_vm._v("Services ")]),
      _c("div", { staticClass: "table-responsive-sm" }, [
        _c(
          "table",
          { staticClass: "table rt-table-striped" },
          [
            _c("thead", [
              _c("tr", { staticClass: "service-rows service-table-headers" }, [
                _c(
                  "th",
                  {
                    staticClass: "center service-name",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("name")
                      }
                    }
                  },
                  [_vm._v("Service")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "center service-name",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("code")
                      }
                    }
                  },
                  [_vm._v("Code")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "left",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("date")
                      }
                    }
                  },
                  [_vm._v("Start - End Date")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "uom-select",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("uom")
                      }
                    }
                  },
                  [
                    _vm._v("UOM "),
                    _c("i", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover",
                          modifiers: { hover: true }
                        }
                      ],
                      staticClass: "fa fa-question-circle",
                      attrs: { title: "Unit Of Measurement" }
                    })
                  ]
                ),
                _c(
                  "th",
                  {
                    staticClass: "price center",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("price")
                      }
                    }
                  },
                  [_vm._v("Price/Unit")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "price center",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("Discounted Price/Unit")
                      }
                    }
                  },
                  [_vm._v("Discounted Price/Unit")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "price center",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("quantity")
                      }
                    }
                  },
                  [_vm._v("Quantity")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "price center",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("discount")
                      }
                    }
                  },
                  [_vm._v("Discount")]
                ),
                _c(
                  "th",
                  {
                    staticClass: "right",
                    on: {
                      click: function($event) {
                        return _vm.sortServices("total")
                      }
                    }
                  },
                  [_vm._v("Total")]
                )
              ])
            ]),
            _c(
              "tbody",
              _vm._l(_vm.unassigned, function(line) {
                return _c(
                  "tr",
                  {
                    staticClass:
                      "service-rows mt-2 service-bg-1 rt-wss-create-afe"
                  },
                  [
                    _c("td", { staticClass: "center price v-align-middle" }, [
                      _c("h4", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v(
                          "Service: " +
                            _vm._s(line.name) +
                            " " +
                            _vm._s(line.vsid)
                        )
                      ]),
                      _c("h5", { staticClass: "mb-0 d-none d-xl-block" }, [
                        _vm._v(_vm._s(line.name))
                      ])
                    ]),
                    _c("td", { staticClass: "center price v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v("Code: " + _vm._s(line.type))
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v(_vm._s(line.type))
                      ])
                    ]),
                    _c("td", { staticClass: "center price v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v("Start - End Date")
                      ]),
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm._f("moment")(line.start_date, "MM/DD/YYYY")
                          ) +
                          " - " +
                          _vm._s(
                            _vm._f("moment")(line.end_date, "MM/DD/YYYY")
                          ) +
                          " "
                      ),
                      _c("br"),
                      _c("span", { staticStyle: { "font-size": "10px" } }, [
                        _vm._v(
                          "(" +
                            _vm._s(
                              parseInt(
                                _vm
                                  .$moment(line.end_date)
                                  .diff(_vm.$moment(line.start_date), "days")
                              ) + 1
                            ) +
                            " Days)"
                        )
                      ])
                    ]),
                    _c("td", { staticClass: "left price v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v("Unit Of Measurement: " + _vm._s(line.uom))
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v(_vm._s(line.uom))
                      ])
                    ]),
                    _c("td", { staticClass: "price left v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v("Price: $" + _vm._s(_vm.formatPrice(line.pu)))
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v("$" + _vm._s(_vm.formatPrice(line.pu)))
                      ])
                    ]),
                    _c("td", { staticClass: "price left v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v(
                          " Discounted Price/Unit:" +
                            _vm._s(
                              line.discount
                                ? "$" +
                                    _vm._discount_price_per_unit(
                                      line.discount,
                                      line.pu
                                    )
                                : line.name
                                ? "$" + _vm.formatPrice(line.pu)
                                : null
                            )
                        )
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v(
                          _vm._s(
                            line.discount
                              ? "$" +
                                  _vm._discount_price_per_unit(
                                    line.discount,
                                    line.pu
                                  )
                              : line.name
                              ? "$" + _vm.formatPrice(line.pu)
                              : null
                          )
                        )
                      ])
                    ]),
                    _c("td", { staticClass: "price left v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v("Quantity: " + _vm._s(parseFloat(line.quantity)))
                      ]),
                      _c("span", { staticClass: "d-none d-xl-inline-block" }, [
                        _vm._v(_vm._s(parseFloat(line.quantity)))
                      ]),
                      /day|Day/.test(line.uom) &&
                      _vm
                        .$moment(line.end_date)
                        .diff(_vm.$moment(line.start_date), "days") +
                        1 !=
                        line.quantity
                        ? _c("span", { staticClass: "ml-1" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "b-tooltip",
                                  rawName: "v-b-tooltip.hover",
                                  modifiers: { hover: true }
                                }
                              ],
                              staticClass: "fa fa-exclamation-triangle",
                              attrs: {
                                title:
                                  "Quantity does not equal sum between start and end dates (" +
                                  (parseInt(
                                    _vm
                                      .$moment(line.end_date)
                                      .diff(
                                        _vm.$moment(line.start_date),
                                        "days"
                                      )
                                  ) +
                                    1) +
                                  ")!"
                              }
                            })
                          ])
                        : _vm._e()
                    ]),
                    _c("td", { staticClass: "price left v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v(
                          "Discount: " +
                            _vm._s(_vm.formatPrice(line.discount)) +
                            "%"
                        )
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v(_vm._s(_vm.formatPrice(line.discount)) + "%")
                      ])
                    ]),
                    _c("td", { staticClass: "right v-align-middle" }, [
                      _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                        _vm._v(
                          "Total: $" +
                            _vm._s(
                              _vm.formatPrice(
                                line.quantity * line.pu -
                                  (line.discount / 100) *
                                    line.quantity *
                                    line.pu
                              )
                            )
                        )
                      ]),
                      _c("span", { staticClass: "d-none d-xl-block" }, [
                        _vm._v(
                          "$" +
                            _vm._s(
                              _vm.formatPrice(
                                line.quantity * line.pu -
                                  (line.discount / 100) *
                                    line.quantity *
                                    line.pu
                              )
                            )
                        )
                      ])
                    ])
                  ]
                )
              }),
              0
            ),
            _vm._l(_vm.merged, function(afe, index) {
              return _c(
                "tbody",
                [
                  _vm._l(afe, function(line) {
                    return _c(
                      "tr",
                      {
                        staticClass:
                          "service-rows mt-2 service-bg rt-wss-create-afe"
                      },
                      [
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h4", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v(
                                "Service: " +
                                  _vm._s(line.service.name) +
                                  " " +
                                  _vm._s(line.service.vsid)
                              )
                            ]),
                            _c(
                              "h5",
                              { staticClass: "mb-0 d-none d-xl-block" },
                              [_vm._v(_vm._s(line.service.name))]
                            )
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Code: " + _vm._s(line.service.type))
                            ]),
                            _c("span", { staticClass: "d-none d-xl-block" }, [
                              _vm._v(_vm._s(line.service.type))
                            ])
                          ]
                        ),
                        _c(
                          "td",
                          { staticClass: "center price v-align-middle" },
                          [
                            _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                              _vm._v("Start - End Date")
                            ]),
                            _vm._v(
                              "\n         " +
                                _vm._s(
                                  _vm._f("moment")(
                                    line.service.start_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " - " +
                                _vm._s(
                                  _vm._f("moment")(
                                    line.service.end_date,
                                    "MM/DD/YYYY"
                                  )
                                ) +
                                " "
                            ),
                            _c("br"),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "10px" } },
                              [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      parseInt(
                                        _vm
                                          .$moment(line.service.end_date)
                                          .diff(
                                            _vm.$moment(
                                              line.service.start_date
                                            ),
                                            "days"
                                          )
                                      ) + 1
                                    ) +
                                    " Days)"
                                )
                              ]
                            )
                          ]
                        ),
                        _c("td", { staticClass: "left price v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Unit Of Measurement: " + _vm._s(line.service.uom)
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(_vm._s(line.service.uom))
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Price: $" +
                                _vm._s(_vm.formatPrice(line.service.pu))
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              "$" + _vm._s(_vm.formatPrice(line.service.pu))
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              " Discounted Price/Unit: " +
                                _vm._s(
                                  line.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          line.discount,
                                          line.pu
                                        )
                                    : line.name
                                    ? "$" + _vm.formatPrice(line.pu)
                                    : null
                                ) +
                                "\n           " +
                                _vm._s(
                                  line.service.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          line.service.discount,
                                          line.service.pu
                                        )
                                    : line.service.name
                                    ? "$" + _vm.formatPrice(line.service.pu)
                                    : null
                                ) +
                                "\n\n         "
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              "\n                " +
                                _vm._s(
                                  line.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          line.discount,
                                          line.pu
                                        )
                                    : line.name
                                    ? "$" + _vm.formatPrice(line.pu)
                                    : null
                                ) +
                                "\n                " +
                                _vm._s(
                                  line.service.discount
                                    ? "$" +
                                        _vm._discount_price_per_unit(
                                          line.service.discount,
                                          line.service.pu
                                        )
                                    : line.service.name
                                    ? "$" + _vm.formatPrice(line.service.pu)
                                    : null
                                ) +
                                "         \n                "
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Quantity: " +
                                _vm._s(parseFloat(line.service.quantity))
                            )
                          ]),
                          _c(
                            "span",
                            { staticClass: "d-none d-xl-inline-block" },
                            [_vm._v(_vm._s(parseFloat(line.service.quantity)))]
                          ),
                          /day|Day/.test(line.service.uom) &&
                          _vm
                            .$moment(line.service.end_date)
                            .diff(
                              _vm.$moment(line.service.start_date),
                              "days"
                            ) +
                            1 !=
                            line.service.quantity
                            ? _c("span", { staticClass: "ml-1" }, [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "b-tooltip",
                                      rawName: "v-b-tooltip.hover",
                                      modifiers: { hover: true }
                                    }
                                  ],
                                  staticClass: "fa fa-exclamation-triangle",
                                  attrs: {
                                    title:
                                      "Quantity does not equal sum between start and end dates (" +
                                      (parseInt(
                                        _vm
                                          .$moment(line.service.end_date)
                                          .diff(
                                            _vm.$moment(
                                              line.service.start_date
                                            ),
                                            "days"
                                          )
                                      ) +
                                        1) +
                                      ")!"
                                  }
                                })
                              ])
                            : _vm._e()
                        ]),
                        _c("td", { staticClass: "price left v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Discount: " +
                                _vm._s(_vm.formatPrice(line.service.discount)) +
                                "%"
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              _vm._s(_vm.formatPrice(line.service.discount)) +
                                "%"
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "right v-align-middle" }, [
                          _c("h6", { staticClass: "d-xs-block d-xl-none" }, [
                            _vm._v(
                              "Total: $" +
                                _vm._s(
                                  _vm.formatPrice(
                                    line.service.quantity * line.service.pu -
                                      (line.service.discount / 100) *
                                        line.service.quantity *
                                        line.service.pu
                                  )
                                )
                            )
                          ]),
                          _c("span", { staticClass: "d-none d-xl-block" }, [
                            _vm._v(
                              "$" +
                                _vm._s(
                                  _vm.formatPrice(
                                    line.service.quantity * line.service.pu -
                                      (line.service.discount / 100) *
                                        line.service.quantity *
                                        line.service.pu
                                  )
                                )
                            )
                          ])
                        ])
                      ]
                    )
                  }),
                  _c("tr", [
                    _c(
                      "td",
                      { staticClass: "p-0", attrs: { colspan: "100%" } },
                      [
                        _c("div", { staticClass: "table-responsive-sm" }, [
                          _c(
                            "table",
                            { staticClass: "table no-bg afenum mb-0" },
                            [
                              _c("thead", { staticClass: "rt-border" }, [
                                _c("tr", { staticClass: "afe-rows" }, [
                                  _c("th", { staticClass: "actions fit" }),
                                  _vm._m(0, true),
                                  _c("th", { staticClass: "center fit" }, [
                                    _c("strong", [
                                      _vm._v("% "),
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName: "v-b-tooltip.hover",
                                            modifiers: { hover: true }
                                          }
                                        ],
                                        staticClass: "fa fa-question-circle",
                                        attrs: { title: "Code Percentage" }
                                      })
                                    ])
                                  ]),
                                  _vm._m(1, true),
                                  _vm._m(2, true),
                                  _vm._m(3, true),
                                  _c("th", { staticClass: "centerx" })
                                ])
                              ]),
                              _c(
                                "tbody",
                                _vm._l(afe[0].afes, function(line, aIndex) {
                                  return _c(
                                    "tr",
                                    { staticClass: "rt-mobile-indent" },
                                    [
                                      _c("td", {
                                        staticClass: "remove-button",
                                        staticStyle: { "border-top": "none" },
                                        attrs: { border: "0" }
                                      }),
                                      _c("td", [
                                        _c("i", {
                                          staticClass:
                                            "fa fa-chevron-circle-right"
                                        }),
                                        _vm._v(
                                          " " +
                                            _vm._s(line.afenum) +
                                            "\n                      "
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Percentage")]
                                        ),
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(line.percentage) +
                                            "\n                      "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(line.ccone_code) +
                                            "\n                      "
                                        )
                                      ]),
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(line.cctwo_code) +
                                            "\n                      "
                                        )
                                      ]),
                                      _c("td", [
                                        _c(
                                          "h6",
                                          {
                                            staticClass: "d-xs-block d-xl-none"
                                          },
                                          [_vm._v("Cost Code #3")]
                                        ),
                                        typeof line.ccthree_code == "string" &&
                                        !line.ccthree_code.includes("---")
                                          ? _c("span", [
                                              _vm._v(_vm._s(line.ccthree_code))
                                            ])
                                          : _vm._e()
                                      ])
                                    ]
                                  )
                                }),
                                0
                              )
                            ]
                          )
                        ])
                      ]
                    )
                  ])
                ],
                2
              )
            })
          ],
          2
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "th",
      { staticClass: "center", staticStyle: { width: "300px" } },
      [_c("strong", [_vm._v("AFE / PO")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "center" }, [
      _c("strong", [_vm._v("Cost Code #1 / Major")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "center" }, [
      _c("strong", [_vm._v("Cost Code #2 / Minor")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("th", { staticClass: "center" }, [
      _c("strong", [_vm._v("Cost Code #3 / Description")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }