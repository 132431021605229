<template>
  <div>
    <table class="table table-clear total-table">
      <tbody>
        <tr v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Subtotal</strong></td>
          <td class="right">${{formatPrice(subtotal)}}</td>
        </tr>
        <tr v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Quick Pay Discount ({{invoice.discount}}%)</strong></td>
          <td class="right">${{formatPrice((subtotal * invoice.discount/100))}}</td>
        </tr>
        <tr v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Quick Pay Subtotal</strong></td>
          <td class="right">${{formatPrice(subtotal - (subtotal * invoice.discount/100))}}</td>
        </tr>
        <tr v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Quickpay GST ({{invoice.tax_percentage}}%)</strong></td>
          <td class="right">${{formatPrice((subtotal - (subtotal * invoice.discount/100)) * (invoice.tax_percentage/100))}}</td>
        </tr>
        <tr v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Quick Pay Total</strong></td>
          <td class="right"><strong>${{formatPrice(invoice.quickpay_total)}} {{currency}}</span></strong></td>
        </tr>
        <tr style="border-bottom: 2px solid #444;" v-if="invoice.quickpay_total > 0 && this.$moment(this.invoice.endtday) > this.$moment()">
          <td class="left"><strong>Number Of Net Days ({{netDays + 1}})</strong></td>
          <td class="right">{{invoice.endtday | moment('MM-DD-YYYY')}}
          </td>
        </tr>
        <tr>
          <td class="left"><strong>Subtotal</strong></td>
          <td class="right">${{formatPrice(subtotal)}}</td>
        </tr>
        <tr>
          <td class="left"><strong>GST ({{invoice.tax_percentage}}%)</strong></td>
          <td class="right">${{tax}}</td>
        </tr>
        <tr>
          <td class="left"><strong>Total</strong></td>
          <td class="right"><strong>${{formatPrice(invoice.total)}} {{currency}}</span></strong></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceTotals',
  props: ['invoice', 'serviceData', 'currency'],
  data: function() {
    return {
      today: new Date(),
    }
  },
  computed: {
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
      },
    tax: function(){
      return this.formatPrice(this.subtotal * (this.invoice.tax_percentage/100 ))
      },
    netDays: function(){
      var a = this.$moment(this.invoice.endtday);
      var b = this.$moment();
      return a.diff(b, 'days')
      },
     },
  mounted() {

  }
}
</script>
