<template>
  <div>
    <h4 class="mb-3">Details:</h4>
    <hr>
    <h5 class="mb-3">{{ invoice.creation_date | moment("MMMM DD, YYYY") }}</h5>
    <ul class="fa-ul mt-2 invoice-detail-lists" v-if="highestAfe && $session.get('user') == 'wss' && invoice.inv_passed != 0">
      <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Project: {{invoice.projectname}}</li>
      <li><span class="fa-li"><i class="fa fa-tint"></i></span>Current UWI - <span v-if="highestAfe.uwi_3">({{highestAfe.uwi_3}}) - </span><span v-if="highestAfe.uwi_2">({{highestAfe.uwi_2}}) - </span><span v-if="highestAfe.uwi">({{highestAfe.uwi}})</span></li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{highestAfe.ln}}</li>
      <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{highestAfe.lsdsurface}}</li>
      <li v-if="highestAfe.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{highestAfe.lsdbottom}}</li>
    </ul>
    <ul class="fa-ul mt-2 invoice-detail-lists" v-if="highestAfe && $session.get('user') != 'wss' && invoice.inv_passed != 0">
      <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Project: {{invoice.projectname}}</li>
      <li><span class="fa-li"><i class="fa fa-tint"></i></span>Current UWI - <span v-if="highestAfe.uwi_3">({{highestAfe.uwi_3}}) - </span><span v-if="highestAfe.uwi_2">({{highestAfe.uwi_2}}) - </span><span v-if="highestAfe.uwi">({{highestAfe.uwi}})</span></li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{highestAfe.ln}}</li>
      <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{highestAfe.lsdsurface}}</li>
      <li v-if="highestAfe.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{highestAfe.lsdbottom}}</li>
    </ul>
    <div v-else-if="vwref && invoice.inv_passed == 0 && $session.get('user') == 'wss'">
      <ul class="fa-ul mt-2 invoice-detail-lists">
        <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Project: {{invoice.projectname}}</li>
        <li><span class="fa-li"><i class="fa fa-tint"></i></span>Current UWI - <span v-if="vwref.uwi_3">({{vwref.uwi_3}}) - </span><span v-if="vwref.uwi_2">({{vwref.uwi_2}}) - </span><span v-if="vwref.uwi">({{vwref.uwi}})</span></li>
        <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{vwref.ln}}</li>
        <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{vwref.lsdsurface}}</li>
        <li v-if="vwref.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{vwref.lsdbottom}}</li>
      </ul>
    </div>
    <div v-else-if="vwref && invoice.inv_passed == 0 && $session.get('user') == 'pm'">
      <ul class="fa-ul mt-2 invoice-detail-lists">
        <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Project: {{invoice.projectname}}</li>
        <li><span class="fa-li"><i class="fa fa-tint"></i></span>Current UWI - <span v-if="vwref.uwi_3">({{vwref.uwi_3}}) - </span><span v-if="vwref.uwi_2">({{vwref.uwi_2}}) - </span><span v-if="vwref.uwi">({{vwref.uwi}})</span></li>
        <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{vwref.ln}}</li>
        <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{vwref.lsdsurface}}</li>
        <li v-if="vwref.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{vwref.lsdbottom}}</li>
      </ul>
    </div>
    <div v-else-if="vwref && invoice.inv_passed == 0 && $session.get('user') == 'vendor'">
      <ul class="fa-ul mt-2 invoice-detail-lists">
        <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Project: {{invoice.projectname}}</li>
        <li><span class="fa-li"><i class="fa fa-tint"></i></span>Current UWI - <span v-if="vwref.uwi_3">({{vwref.uwi_3}}) - </span><span v-if="vwref.uwi_2">({{vwref.uwi_2}}) - </span><span v-if="vwref.uwi">({{vwref.uwi}})</span></li>
        <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>License Number: {{vwref.ln}}</li>
        <li><span class="fa-li"><i class="fa fa-location"></i></span>Surface Location: {{vwref.lsdsurface}}</li>
        <li v-if="vwref.lsdbottom !== '----'"><span class="fa-li"><i class="fa fa-location"></i></span>Bottom Location: {{vwref.lsdbottom}}</li>
      </ul>
    </div>
    <ul class="fa-ul mt-2 invoice-detail-lists">
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>Field Ticket Number: {{invoice.ticketnum || '&mdash;'}}</li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>Vendor Invoice Number: {{invoice.vendor_invoice_number || '&mdash;'}}</li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>Service Order Number: {{invoice.service_order_number || '&mdash;'}}</li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>Program Number: {{invoice.program_number || '&mdash;'}}</li>
      <li><span class="fa-li"><i class="fa fa-project-diagram"></i></span>Treatment Type: {{invoice.treatment_type || '&mdash;'}}</li>
      <li><span class="fa-li"><i class="fa fa-hashtag"></i></span>Vendor Invoice Date: {{invoice.vendor_invoice_date ? $moment(invoice.vendor_invoice_date).format('MM/DD/YYYY') : '&mdash;'}}</li>
    </ul>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceDetails',
  props: ['invoice', 'vwref', 'highestAfe'],
  data: function() {
    return {

    }
  },
}
</script>
