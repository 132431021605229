<template>
  <div><h4 class="d-inline"><i class="nav-icon icon-layers"></i> Invoice No: {{invoice.invnum}}
  <b-button class="pull-right pl-3 btn-sm font-weight-bold" variant="primary" @click="printInvoice(invoice, vwref, highestAfe, serviceData, afes, currency, subtotal, tax, company_name, manager, vendorLogo, companyLogo, allHistorySorted, invoiceModificationHistoryChanges)"><span class="mt-2 d-none d-sm-inline mr-1">Print / Download PDF </span><i class="fa fa-print"></i></b-button></h4></div>
</template>

<script type="text/javascript">
import * as jwt_decode from 'jwt-decode';
//import { resizeBase64ForMaxHeight } from 'resize-base64'
import { resizeBase64ForMaxHeight } from 'resize-base64'

export default {
  name: 'InvoiceHeader',
  props: ['invoice', 'manager', 'vwref', 'highestAfe', 'serviceData', 'afes', 'currency', 'company_name', 'vid'],
  data: function() {
    return {
      companyLogo: "",
      vendorLogo: "",
      allHistory: [],
      invoiceModificationHistoryChanges: [],
    }
  },
  methods: {
    successVendor: function(resizedImage) {
      this.vendorLogo = resizedImage;
      //console.log(resizedImage, 'vendor')
    },
    successCompany: function(resizedImage) {
      //console.log('company')
      this.companyLogo = resizedImage;
      //console.log(resizedImage, 'company')
    },
    error: function(errorMsg) {
      //console.log('companye')
     //console.log(errorMsg, 'error');
    },
    loadHistory() {
      var user = this.$session.get('user');
      var url = '/' + user + '/get/invoice_history/' + this.invoice.invoiceid

      if(user == 'pm'){
        user = "wts";
      }
      if(user == 'accountant'){
        user = "manager";
      }
      if(user == 'wts' || user == 'manager' || user == 'wss'){
        url = '/' + user + '/get/invoice_history/' + this.invoice.projectid + "/" + this.invoice.invoiceid
      }

      this.$axios.get('/invoice_modification_history/get/' + this.invoice.invoiceid)
      .then(response => {

        this.invoiceModificationHistoryChanges = this.lodash.chain(response.data.result.reverse())
        .groupBy('creation_date')
        .map((code, date) => ({date, code }))
        .value();

        for (var i = 0; i < this.invoiceModificationHistoryChanges.length; i++) {
          this.invoiceModificationHistoryChanges[i].groupedUpdatedServices = this.lodash.chain(this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'UPDATE'))
          .groupBy('service_name')
          .map((code, name) => ({name, code }))
          .value();;


          this.invoiceModificationHistoryChanges[i].removedServices = this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'DELETE');
          this.invoiceModificationHistoryChanges[i].addedServices = this.invoiceModificationHistoryChanges[i].code.filter(x=>x.edit_type == 'ADD');
        }


      })
      .catch(error => {
      })

      this.$http.get(url).then(response => {
        //this.allHistory = response.data.result;

        for (var i = 0; i < response.data.result.length; i++) {
          var comment = response.data.result[i].history_string;

          if(comment.mgr5_mdate != null){
            var status = "Approved";
            var date = comment.mgr5_mdate;
            if(comment.inv_passed == 54){
              status = "Rejected";
            }
            if(comment.mgr5_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.mgr5_note,
              "date": date,
              "user": "Management"
            })
          }
          if(comment.mgr4_mdate != null){
            var status = "Approved";
            var date = comment.mgr4_mdate;
            if(comment.inv_passed == 44){
              status = "Rejected";
            }
            if(comment.mgr4_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.mgr4_note,
              "date": date,
              "user": "Management"
            })
          }
          if(comment.mgr3_mdate != null){
            var status = "Approved";
            var date = comment.mgr3_mdate;
            if(comment.inv_passed == 34){
              status = "Rejected";
            }
            if(comment.mgr3_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.mgr3_note,
              "date": date,
              "user": "Management"
            })
          }
          if(comment.mgr2_mdate != null){
            var status = "Approved";
            var date = comment.mgr2_mdate;
            if(comment.inv_passed == 24){
              status = "Rejected";
            }
            if(comment.mgr2_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.mgr2_note,
              "date": date,
              "user": "Management"
            })
          }
          if(comment.mgr1_mdate != null){
            var status = "Approved";
            var date = comment.mgr1_mdate;
            if(comment.inv_passed == 14){
              status = "Rejected";
            }
            if(comment.mgr1_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.mgr1_note,
              "date": date,
              "user": "Management"
            })
          }

          if(comment.vendor_mdate != null){
            this.allHistory.push({
              "status": "Resubmitted",
              "comment": comment.vendor_note,
              "date": comment.vendor_mdate,
              "user": "Vendor"
            })
          }
          if(comment.wss_mdate != null){
            var status = "Approved";
            if(comment.inv_passed == 9){
              status = "Rejected";
            }
            this.allHistory.push({
              "status": status,
              "comment": comment.wss_note,
              "date": comment.wss_mdate,
              "user": "Well Site Supervisor"
            })
          }
          if(comment.wts_mdate != null){
            var date = comment.wts_mdate;
            var status = "Rejected";
            if(comment.inv_passed == 6 ||
              comment.inv_passed == 7 ||
              comment.inv_passed >= 11 ) {
              status = "Approved";
            }
            if(comment.wts_mdate.fn == "NOW"){
              date = response.data.result[i].creation_date;
            }

            this.allHistory.push({
              "status": status,
              "comment": comment.wts_note,
              "date": date,
              "user": "Project Manager"
            })

            // if(comment.wss_mdate == null || this.allHistory.filter(x=>x.date == comment.wss_mdate).length > 0){
            //   this.allHistory.push({
            //     "status": "Approved / Rejected Bypassed By PM",
            //     "date": this.$moment.utc(date).subtract(2, 'seconds').format(),
            //     "user": "Well Site Supervisor"
            //   })
            // }
          }
        }

        if(this.invoice.mgr5_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 51){
            status = "Approved";
          }
          if(this.invoice.inv_passed == 54){
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.mgr5_note,
            "date": this.invoice.mgr5_mdate,
            "user": "Management"
          })
        }
        if(this.invoice.mgr4_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 41 ||
             this.invoice.inv_passed == 43 ||
             this.invoice.inv_passed >= 51){
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.mgr4_note,
            "date": this.invoice.mgr4_mdate,
            "user": "Management"
          })
        }
        if(this.invoice.mgr3_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 31 ||
             this.invoice.inv_passed == 33 ||
             this.invoice.inv_passed >= 41){
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.mgr3_note,
            "date": this.invoice.mgr3_mdate,
            "user": "Management"
          })
        }
        if(this.invoice.mgr2_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 21 ||
             this.invoice.inv_passed == 23 ||
             this.invoice.inv_passed >= 31){
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.mgr2_note,
            "date": this.invoice.mgr2_mdate,
            "user": "Management"
          })

        }
        if(this.invoice.mgr1_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 11 ||
             this.invoice.inv_passed == 13 ||
             this.invoice.inv_passed >= 21){
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.mgr1_note,
            "date": this.invoice.mgr1_mdate,
            "user": "Management"
          })
        }

        if(this.invoice.wts_mdate != null){
          var status = "Rejected";
          if(this.invoice.inv_passed == 6 ||
            this.invoice.inv_passed == 7 ||
            this.invoice.inv_passed >= 11 ) {
            status = "Approved";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.wts_note,
            "date": this.invoice.wts_mdate,
            "user": "Project Manager"
          })

          // if(this.invoice.wss_mdate == null || this.allHistory.filter(x=>x.date == this.invoice.wss_mdate).length > 0){
          //   this.allHistory.push({
          //     "status": "Approved / Rejected Bypassed By PM",
          //     "date": this.$moment.utc(this.invoice.wts_mdate).subtract(2, 'seconds').format(),
          //     "user": "Well Site Supervisor"
          //   })
          // }
        }
        if(this.invoice.wss_mdate != null){
          var status = "Approved";
          if(this.invoice.inv_passed == 9){
            status = "Rejected";
          }

          this.allHistory.push({
            "status": status,
            "comment": this.invoice.wss_note,
            "date": this.invoice.wss_mdate,
            "user": "Well Site Supervisor"
          })
        }
        if(this.invoice.vendor_mdate != null){
          var status = "Submitted";
          if(response.data.result.length > 0){
            status = "Resubmitted";
          }
          this.allHistory.push({
            "status": status,
            "comment": this.invoice.vendor_note,
            "date": this.invoice.vendor_mdate,
            "user": "Vendor"
          })
        }
      });
    }
  },
  watch: {
    invoice: function(){
      this.loadHistory();

      this.$http.get('/get/company/avatar/' + this.invoice.companyid ).then(response => {
        resizeBase64ForMaxHeight(response.data.avatar, 240, 152, this.successCompany, this.error);
      }).catch(error => {
        //console.log("Error getting avatar " + error);
      })
      if(this.vid){
        let token = this.$session.get('jwt');
        var decoded = jwt_decode(token);
        this.$http.get('/get/vendor/avatar/' + decoded.id ).then(response => {
          resizeBase64ForMaxHeight(response.data.avatar, 240, 152, this.successVendor, this.error);
        }).catch(error => {
            //console.log("Error getting avatar " + error);
        })
      }
      else {
        this.$http.get('/get/vendor/avatar/' + this.invoice.vid ).then(response => {
          resizeBase64ForMaxHeight(response.data.avatar, 240, 152, this.successVendor, this.error);
        }).catch(error => {
            //console.log("Error getting avatar " + error);``
        })
      }
    }
  },
  mounted() {

  },
  computed: {
    allHistorySorted: function(){
      var removeDuplicates = this.allHistory;
      var comp = "date";
      var arr = this.allHistory;
      const unique = arr
        .map(e => e[comp])
        // store the keys of the unique objects
        .map((e, i, final) => final.indexOf(e) === i && i)
        // eliminate the dead keys & store unique objects
        .filter(e => arr[e]).map(e => arr[e]);

      return this.lodash.sortBy(unique, 'date').reverse();
    },
    subtotal: function(){
      return this.serviceData.reduce(function(total, item){
        return total + (item.quantity * item.pu) - ((item.discount/100) * item.quantity * item.pu);
      },0);
    },
    tax: function(){
      return this.formatPrice(this.subtotal * (this.invoice.tax_percentage/100 ))
    },
  },
}
</script>
