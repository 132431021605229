<template>
  <div>
    <h4 class="mb-3">Billing To:</h4>
    <hr>
    <div class="mt-3 mb-3" v-if="avatar">
      <img class="companylogo" v-bind:src="avatar" />
    </div>
    <h5 class="mb-3">{{company_name}}</h5>
    <ul class="fa-ul invoice-detail-lists">
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.company_addressline1}}</li>
      <li v-if="invoice.company_addressline2"><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.company_addressline2}}</li>
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.company_city}}, {{invoice.company_province}}</li>
      <li><span class="fa-li"><i class="fa fa-building"></i></span>{{invoice.company_postalcode}}</li>
    </ul>
  </div>
</template>

<script type="text/javascript">
export default {
  name: 'CompanyDetails',
  props: ['invoice', 'company_name'],
  data: function() {
    return {
      avatar: ""
    }
  },
  watch: {
    invoice: function(){
      if(this.invoice.companyid > 0){
        this.$axios.get('/get/company/avatar/' + this.invoice.companyid ).then(response => {
            this.avatar = response.data.avatar;
        }).catch(error => {
            //console.log("Error getting avatar " + error);
        })
      }
    }
  },
}
</script>
