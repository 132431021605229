<template>
<div> 
  <div class="mt-4">
    <h4>Services </h4>
    <div class="table-responsive-sm">
      <table class="table rt-table-striped">
        <thead>
          <tr class="service-rows service-table-headers">
            <th class="center service-name" @click="sortServices('name')">Service</th>
            <th class="center service-name" @click="sortServices('code')">Code</th>
            <th class="left" @click="sortServices('date')">Start - End Date</th>
            <th class="uom-select" @click="sortServices('uom')">UOM <i class="fa fa-question-circle" v-b-tooltip.hover title="Unit Of Measurement"></i></th>
            <th class="price center" @click="sortServices('price')">Price/Unit</th>
            <th class="price center" @click="sortServices('Discounted Price/Unit')">Discounted Price/Unit</th>
            <th class="price center" @click="sortServices('quantity')">Quantity</th>
            <th class="price center" @click="sortServices('discount')">Discount</th>
            <th class="right" @click="sortServices('total')">Total</th>
          </tr>
        </thead>
        
        <tbody>
          <tr class="service-rows mt-2 service-bg-1 rt-wss-create-afe" v-for="line in unassigned">
            <td class="center price v-align-middle">
              <h4 class="d-xs-block d-xl-none">Service: {{line.name}} {{line.vsid}}</h4>
              <h5 class="mb-0 d-none d-xl-block">{{line.name}}</h5>
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Code: {{line.type}}</h6>
              <span class="d-none d-xl-block">{{line.type}}</span>
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
            {{line.start_date | moment("MM/DD/YYYY")}} - {{line.end_date | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(line.end_date).diff($moment(line.start_date), 'days')) + 1)}} Days)</span>
            </td>
            <td class="left price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{line.uom}}</h6>
              <span class="d-none d-xl-block">{{line.uom}}</span>
            </td>
            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(line.pu)}}</h6>
              <span class="d-none d-xl-block">${{formatPrice(line.pu)}}</span>
            </td>

             <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none"> Discounted Price/Unit:{{line.discount ?  '$' + _discount_price_per_unit(line.discount,line.pu) : line.name ?  '$' + formatPrice(line.pu) : null }}</h6>
              <span class="d-none d-xl-block">{{line.discount ?  '$' + _discount_price_per_unit(line.discount,line.pu) : line.name ?  '$' + formatPrice(line.pu) : null }}</span>
           </td>

            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(line.quantity)}}</h6>
              <span class="d-none d-xl-inline-block">{{parseFloat(line.quantity)}}</span>
              <span class="ml-1" v-if="/day|Day/.test(line.uom) && $moment(line.end_date).diff($moment(line.start_date), 'days') + 1 != line.quantity">
                <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(line.end_date).diff($moment(line.start_date), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
              </span>
            </td>
            <td class="price left v-align-middle">
        <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(line.discount)}}%</h6>
              <span class="d-none d-xl-block">{{formatPrice(line.discount)}}%</span>
            </td>
            <td class="right v-align-middle">
              <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((line.quantity * line.pu) - ((line.discount/100) * line.quantity * line.pu))}}</h6>
              <span class="d-none d-xl-block">${{formatPrice((line.quantity * line.pu) - ((line.discount/100) * line.quantity * line.pu))}}</span>
            </td>
          </tr>
        </tbody>
        <tbody v-for="(afe, index) in merged">
          <tr class="service-rows mt-2 service-bg rt-wss-create-afe" v-for="line in afe">
            <td class="center price v-align-middle">
     <h4 class="d-xs-block d-xl-none">Service: {{line.service.name}} {{line.service.vsid}}</h4>
              <h5 class="mb-0 d-none d-xl-block">{{line.service.name}}</h5>
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Code: {{line.service.type}}</h6>
           <span class="d-none d-xl-block">{{line.service.type}}</span>
            </td>
            <td class="center price v-align-middle">
              <h6 class="d-xs-block d-xl-none">Start - End Date</h6>
         {{line.service.start_date | moment("MM/DD/YYYY")}} - {{line.service.end_date | moment("MM/DD/YYYY")}} <br><span style="font-size: 10px;">({{(parseInt($moment(line.service.end_date).diff($moment(line.service.start_date), 'days')) + 1)}} Days)</span>
            </td>
            <td class="left price v-align-middle">
             <h6 class="d-xs-block d-xl-none">Unit Of Measurement: {{line.service.uom}}</h6>
              <span class="d-none d-xl-block">{{line.service.uom}}</span>
            </td>
            <td class="price left v-align-middle">
        <h6 class="d-xs-block d-xl-none">Price: ${{formatPrice(line.service.pu)}}</h6>
              <span class="d-none d-xl-block">${{formatPrice(line.service.pu)}}</span>
            </td>
              <td class="price left v-align-middle">
         <h6 class="d-xs-block d-xl-none"> Discounted Price/Unit: {{line.discount ?  '$' + _discount_price_per_unit(line.discount,line.pu) : line.name ?  '$' + formatPrice(line.pu) : null }}
           {{line.service.discount ?  '$' + _discount_price_per_unit(line.service.discount,line.service.pu) : line.service.name ?  '$' + formatPrice(line.service.pu) : null }}

         </h6>
              <span class="d-none d-xl-block">
                {{line.discount ?  '$' + _discount_price_per_unit(line.discount,line.pu) : line.name ?  '$' + formatPrice(line.pu) : null }}
                {{line.service.discount ?  '$' + _discount_price_per_unit(line.service.discount,line.service.pu) : line.service.name ?  '$' + formatPrice(line.service.pu) : null }}         
                </span>

                </td>
            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Quantity: {{parseFloat(line.service.quantity)}}</h6>
              <span class="d-none d-xl-inline-block">{{parseFloat(line.service.quantity)}}</span>
              <span class="ml-1" v-if="/day|Day/.test(line.service.uom) && $moment(line.service.end_date).diff($moment(line.service.start_date), 'days') + 1 != line.service.quantity">
                <i v-b-tooltip.hover :title="'Quantity does not equal sum between start and end dates (' + (parseInt($moment(line.service.end_date).diff($moment(line.service.start_date), 'days')) + 1) + ')!'" class="fa fa-exclamation-triangle"></i>
              </span>
            </td>
            <td class="price left v-align-middle">
              <h6 class="d-xs-block d-xl-none">Discount: {{formatPrice(line.service.discount)}}%</h6>
          <span class="d-none d-xl-block">{{formatPrice(line.service.discount)}}%</span>
            </td>
            <td class="right v-align-middle">
           <h6 class="d-xs-block d-xl-none">Total: ${{formatPrice((line.service.quantity * line.service.pu) - ((line.service.discount/100) * line.service.quantity * line.service.pu))}}</h6>
              <span class="d-none d-xl-block">${{formatPrice((line.service.quantity * line.service.pu) - ((line.service.discount/100) * line.service.quantity * line.service.pu))}}</span>
            </td>
          </tr>
          <tr>
            <td class="p-0" colspan="100%">
              <div class="table-responsive-sm">
                <table class="table no-bg afenum mb-0">
                  <thead class="rt-border">
                    <tr class="afe-rows">
                      <th class="actions fit"></th>
                      <th class="center" style="width: 300px;"><strong>AFE / PO</strong></th>
                      <th class="center fit"><strong>% <i class="fa fa-question-circle" v-b-tooltip.hover title="Code Percentage"></i></strong></th>
                      <th class="center"><strong>Cost Code #1 / Major</strong></th>
                      <th class="center"><strong>Cost Code #2 / Minor</strong></th>
                      <th class="center"><strong>Cost Code #3 / Description</strong></th>
                      <th class="centerx"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(line, aIndex) in afe[0].afes" class="rt-mobile-indent">
                      <td border="0" style="border-top: none;" class="remove-button"></td>
                      <td>
                        <i class="fa fa-chevron-circle-right"></i> {{line.afenum}}
                      </td>
                      <td>
                        <h6 class="d-xs-block d-xl-none">Percentage</h6>
                        {{line.percentage}}
                      </td>
                      <td>
                        {{line.ccone_code}}
                      </td>
                      <td>
                        {{line.cctwo_code}}
                      </td>
                      <td>
                        <h6 class="d-xs-block d-xl-none">Cost Code #3</h6>
                        <span v-if="typeof(line.ccthree_code) == 'string' && !line.ccthree_code.includes('---')">{{line.ccthree_code}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- {{afes}}
      <br>
      {{merged}} -->
    </div>
  </div>
</div>
</template>

<script type="text/javascript">
export default {
  name: 'InvoiceAfes',
  props: ['afes', 'serviceData'],
  data: function() {
    return {
      order: "",
      ascDesc: 'asc',
      toggle: false,
      fieldTicketNumber:'',
      vendorInvoiceNumber:'',
      serviceOrderNumber:'',
      programNumber:'',
      treatmentType:'',
      vendorInvoiceDate:new Date().toISOString().slice(0,10),
      serviceDatas:[]
    }
  },
  methods: {
    sortServices(sort) {
      this.toggle = !this.toggle
      if(this.toggle){
        this.ascDesc = "asc"
      }
      else {
        this.ascDesc = "desc";
      }
      if(sort == "name"){
        this.order = "name";
      }
      if(sort == "type"){
        this.order = "type";
      }
      if(sort == "date"){
        this.order = "start_date";
      }
      if(sort == "uom"){
        this.order = "uom";
      }
      if(sort == "price"){
        this.order = "pu";
      }
      if(sort == "quantity"){
        this.order = "quantity";
      }
      if(sort == "discount"){
        this.order = "discount";
      }
      if(sort == "total"){
        this.order = "total";
      }
       if(sort == "Discounted Price/Unit"){
        this.order = "Discounted Price/Unit";
      }
    }
  },
  computed: {
    sortedAfes: function(){
      // var temp = this.lodash.chain(this.afesProp)
      // .groupBy(service => line.service.vsid)
      // .map((services, vsid) => ({vsid, services}))
      // .value();
      // return this.lodash.orderBy(temp, 'services[0].' + this.order, this.ascDesc);
      var temp = this.lodash.chain(this.afesProp)
      .groupBy(service => line.service.vsid)
      .map((services, vsid) => ({vsid, services}))
      .value();
      return this.lodash.orderBy(temp, 'services[0].' + this.order, this.ascDesc);
    },
    afesProp: function(){
      const that = this;
      return this.afes.map(function(i) {
        i.total = that.formatPrice((i.quantity * i.pu) - ((i.discount/100) * i.quantity * i.pu))
        return i;
      });
    },
    unassigned: function(){
      console.log(this.serviceData,"data");
      if(this.serviceData){
        var data = [];
        this.serviceData.forEach(service => {
          if(this.afesProp.filter(x=>x.vsid == service.vsid).length == 0){
            data.push(service)
          }
        })
        return data;
      }
      else {
        var blank = [];
        return blank;
      }
    },
    merged: function(){
      var afeTemp = [];
      var serviceTemp = [];
      var uni = this.lodash.uniqBy(this.afesProp, "vsid");
      console.log(uni,"uni");
      uni.forEach(afe => {
        var filter = this.afesProp.filter(x=>x.vsid == afe.vsid);
        var allAfes = [];
        for (var i = 0; i < filter.length; i++) {
          allAfes.push({
            "afenum": filter[i].afenum,
            "ccone_code": filter[i].ccone_code,
            "cctwo_code": filter[i].cctwo_code,
            "ccthree_code": filter[i].ccthree_code,
            "percentage": filter[i].percentage,
          })
        }
        afeTemp.push({
          service: afe,
          afes: allAfes
        })
      });
      var group = [];
      afeTemp.forEach(afe => {
        var filter = afeTemp.filter(x=>JSON.stringify(x.afes) == JSON.stringify(afe.afes));
        if(filter.length > 0){
          if(group.filter(x => JSON.stringify(x) == JSON.stringify(filter)).length == 0){
            group.push(filter);
          }
        }
      });
      return group;
    },
  }
}
</script>

<style media="screen" lang="scss" scoped>
  table {
    margin-bottom: 0;
    border-collapse: collapse;
    td:nth-child(2),
    th:nth-child(2){
    }
    th, td {
      border: none !important;
    }
    .rt-border th {
      border-bottom: 2px solid #444 !important;
    }
    .rt-border th:first-child,
    .rt-border td:first-child {
      border: none !important;
      min-width: 14%;
    }
  }
  .service-table-headers th {
    font-size: 1.1em;
  }
  .service-bg {
    background: #484848;
    color: #fff;
    font-weight: bold;
  }
   .service-bg-1{
    background: #8C8C8C;
    color: #fff;
    font-weight: bold;
  }
  .afe-table {
    td {
      width: 16.66%;
    }
    td:first-child {
      width: 60px;
    }
  }
  .afe-rows {
    th {
      font-weight: normal;
    }
  }
  table .rt-tbody-spacing {
    border-top: 20px solid white;
  }
  .rt-table-striped > tbody:nth-of-type(2n+1) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  .rt-table-striped tbody .no-bg {
    background-color: rgba(0, 0, 0, 0);
  }
  @media screen and (max-width: 1200px){
    .afe-rows th {
      display: none !important;
    }
  }
</style>