var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h4", { staticClass: "mb-3" }, [_vm._v("Details:")]),
    _c("hr"),
    _c("h5", { staticClass: "mb-3" }, [
      _vm._v(
        _vm._s(_vm._f("moment")(_vm.invoice.creation_date, "MMMM DD, YYYY"))
      )
    ]),
    _vm.highestAfe &&
    _vm.$session.get("user") == "wss" &&
    _vm.invoice.inv_passed != 0
      ? _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
          _c("li", [
            _vm._m(0),
            _vm._v("Project: " + _vm._s(_vm.invoice.projectname))
          ]),
          _c("li", [
            _vm._m(1),
            _vm._v("Current UWI - "),
            _vm.highestAfe.uwi_3
              ? _c("span", [
                  _vm._v("(" + _vm._s(_vm.highestAfe.uwi_3) + ") - ")
                ])
              : _vm._e(),
            _vm.highestAfe.uwi_2
              ? _c("span", [
                  _vm._v("(" + _vm._s(_vm.highestAfe.uwi_2) + ") - ")
                ])
              : _vm._e(),
            _vm.highestAfe.uwi
              ? _c("span", [_vm._v("(" + _vm._s(_vm.highestAfe.uwi) + ")")])
              : _vm._e()
          ]),
          _c("li", [
            _vm._m(2),
            _vm._v("License Number: " + _vm._s(_vm.highestAfe.ln))
          ]),
          _c("li", [
            _vm._m(3),
            _vm._v("Surface Location: " + _vm._s(_vm.highestAfe.lsdsurface))
          ]),
          _vm.highestAfe.lsdbottom !== "----"
            ? _c("li", [
                _vm._m(4),
                _vm._v("Bottom Location: " + _vm._s(_vm.highestAfe.lsdbottom))
              ])
            : _vm._e()
        ])
      : _vm._e(),
    _vm.highestAfe &&
    _vm.$session.get("user") != "wss" &&
    _vm.invoice.inv_passed != 0
      ? _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
          _c("li", [
            _vm._m(5),
            _vm._v("Project: " + _vm._s(_vm.invoice.projectname))
          ]),
          _c("li", [
            _vm._m(6),
            _vm._v("Current UWI - "),
            _vm.highestAfe.uwi_3
              ? _c("span", [
                  _vm._v("(" + _vm._s(_vm.highestAfe.uwi_3) + ") - ")
                ])
              : _vm._e(),
            _vm.highestAfe.uwi_2
              ? _c("span", [
                  _vm._v("(" + _vm._s(_vm.highestAfe.uwi_2) + ") - ")
                ])
              : _vm._e(),
            _vm.highestAfe.uwi
              ? _c("span", [_vm._v("(" + _vm._s(_vm.highestAfe.uwi) + ")")])
              : _vm._e()
          ]),
          _c("li", [
            _vm._m(7),
            _vm._v("License Number: " + _vm._s(_vm.highestAfe.ln))
          ]),
          _c("li", [
            _vm._m(8),
            _vm._v("Surface Location: " + _vm._s(_vm.highestAfe.lsdsurface))
          ]),
          _vm.highestAfe.lsdbottom !== "----"
            ? _c("li", [
                _vm._m(9),
                _vm._v("Bottom Location: " + _vm._s(_vm.highestAfe.lsdbottom))
              ])
            : _vm._e()
        ])
      : _vm.vwref &&
        _vm.invoice.inv_passed == 0 &&
        _vm.$session.get("user") == "wss"
      ? _c("div", [
          _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
            _c("li", [
              _vm._m(10),
              _vm._v("Project: " + _vm._s(_vm.invoice.projectname))
            ]),
            _c("li", [
              _vm._m(11),
              _vm._v("Current UWI - "),
              _vm.vwref.uwi_3
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_3) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi_2
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_2) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi) + ")")])
                : _vm._e()
            ]),
            _c("li", [
              _vm._m(12),
              _vm._v("License Number: " + _vm._s(_vm.vwref.ln))
            ]),
            _c("li", [
              _vm._m(13),
              _vm._v("Surface Location: " + _vm._s(_vm.vwref.lsdsurface))
            ]),
            _vm.vwref.lsdbottom !== "----"
              ? _c("li", [
                  _vm._m(14),
                  _vm._v("Bottom Location: " + _vm._s(_vm.vwref.lsdbottom))
                ])
              : _vm._e()
          ])
        ])
      : _vm.vwref &&
        _vm.invoice.inv_passed == 0 &&
        _vm.$session.get("user") == "pm"
      ? _c("div", [
          _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
            _c("li", [
              _vm._m(15),
              _vm._v("Project: " + _vm._s(_vm.invoice.projectname))
            ]),
            _c("li", [
              _vm._m(16),
              _vm._v("Current UWI - "),
              _vm.vwref.uwi_3
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_3) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi_2
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_2) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi) + ")")])
                : _vm._e()
            ]),
            _c("li", [
              _vm._m(17),
              _vm._v("License Number: " + _vm._s(_vm.vwref.ln))
            ]),
            _c("li", [
              _vm._m(18),
              _vm._v("Surface Location: " + _vm._s(_vm.vwref.lsdsurface))
            ]),
            _vm.vwref.lsdbottom !== "----"
              ? _c("li", [
                  _vm._m(19),
                  _vm._v("Bottom Location: " + _vm._s(_vm.vwref.lsdbottom))
                ])
              : _vm._e()
          ])
        ])
      : _vm.vwref &&
        _vm.invoice.inv_passed == 0 &&
        _vm.$session.get("user") == "vendor"
      ? _c("div", [
          _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
            _c("li", [
              _vm._m(20),
              _vm._v("Project: " + _vm._s(_vm.invoice.projectname))
            ]),
            _c("li", [
              _vm._m(21),
              _vm._v("Current UWI - "),
              _vm.vwref.uwi_3
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_3) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi_2
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi_2) + ") - ")])
                : _vm._e(),
              _vm.vwref.uwi
                ? _c("span", [_vm._v("(" + _vm._s(_vm.vwref.uwi) + ")")])
                : _vm._e()
            ]),
            _c("li", [
              _vm._m(22),
              _vm._v("License Number: " + _vm._s(_vm.vwref.ln))
            ]),
            _c("li", [
              _vm._m(23),
              _vm._v("Surface Location: " + _vm._s(_vm.vwref.lsdsurface))
            ]),
            _vm.vwref.lsdbottom !== "----"
              ? _c("li", [
                  _vm._m(24),
                  _vm._v("Bottom Location: " + _vm._s(_vm.vwref.lsdbottom))
                ])
              : _vm._e()
          ])
        ])
      : _vm._e(),
    _c("ul", { staticClass: "fa-ul mt-2 invoice-detail-lists" }, [
      _c("li", [
        _vm._m(25),
        _vm._v("Field Ticket Number: " + _vm._s(_vm.invoice.ticketnum || "—"))
      ]),
      _c("li", [
        _vm._m(26),
        _vm._v(
          "Vendor Invoice Number: " +
            _vm._s(_vm.invoice.vendor_invoice_number || "—")
        )
      ]),
      _c("li", [
        _vm._m(27),
        _vm._v(
          "Service Order Number: " +
            _vm._s(_vm.invoice.service_order_number || "—")
        )
      ]),
      _c("li", [
        _vm._m(28),
        _vm._v("Program Number: " + _vm._s(_vm.invoice.program_number || "—"))
      ]),
      _c("li", [
        _vm._m(29),
        _vm._v("Treatment Type: " + _vm._s(_vm.invoice.treatment_type || "—"))
      ]),
      _c("li", [
        _vm._m(30),
        _vm._v(
          "Vendor Invoice Date: " +
            _vm._s(
              _vm.invoice.vendor_invoice_date
                ? _vm
                    .$moment(_vm.invoice.vendor_invoice_date)
                    .format("MM/DD/YYYY")
                : "—"
            )
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-tint" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-tint" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-tint" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-tint" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-tint" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-location" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-project-diagram" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "fa-li" }, [
      _c("i", { staticClass: "fa fa-hashtag" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }