var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "table table-clear total-table" }, [
      _c("tbody", [
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", [
              _vm._m(0),
              _c("td", { staticClass: "right" }, [
                _vm._v("$" + _vm._s(_vm.formatPrice(_vm.subtotal)))
              ])
            ])
          : _vm._e(),
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", [
              _c("td", { staticClass: "left" }, [
                _c("strong", [
                  _vm._v(
                    "Quick Pay Discount (" + _vm._s(_vm.invoice.discount) + "%)"
                  )
                ])
              ]),
              _c("td", { staticClass: "right" }, [
                _vm._v(
                  "$" +
                    _vm._s(
                      _vm.formatPrice(
                        (_vm.subtotal * _vm.invoice.discount) / 100
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", [
              _vm._m(1),
              _c("td", { staticClass: "right" }, [
                _vm._v(
                  "$" +
                    _vm._s(
                      _vm.formatPrice(
                        _vm.subtotal -
                          (_vm.subtotal * _vm.invoice.discount) / 100
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", [
              _c("td", { staticClass: "left" }, [
                _c("strong", [
                  _vm._v(
                    "Quickpay GST (" + _vm._s(_vm.invoice.tax_percentage) + "%)"
                  )
                ])
              ]),
              _c("td", { staticClass: "right" }, [
                _vm._v(
                  "$" +
                    _vm._s(
                      _vm.formatPrice(
                        (_vm.subtotal -
                          (_vm.subtotal * _vm.invoice.discount) / 100) *
                          (_vm.invoice.tax_percentage / 100)
                      )
                    )
                )
              ])
            ])
          : _vm._e(),
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", [
              _vm._m(2),
              _c("td", { staticClass: "right" }, [
                _c("strong", [
                  _vm._v(
                    "$" +
                      _vm._s(_vm.formatPrice(_vm.invoice.quickpay_total)) +
                      " " +
                      _vm._s(_vm.currency)
                  )
                ])
              ])
            ])
          : _vm._e(),
        _vm.invoice.quickpay_total > 0 &&
        this.$moment(this.invoice.endtday) > this.$moment()
          ? _c("tr", { staticStyle: { "border-bottom": "2px solid #444" } }, [
              _c("td", { staticClass: "left" }, [
                _c("strong", [
                  _vm._v("Number Of Net Days (" + _vm._s(_vm.netDays + 1) + ")")
                ])
              ]),
              _c("td", { staticClass: "right" }, [
                _vm._v(
                  _vm._s(_vm._f("moment")(_vm.invoice.endtday, "MM-DD-YYYY")) +
                    "\n        "
                )
              ])
            ])
          : _vm._e(),
        _c("tr", [
          _vm._m(3),
          _c("td", { staticClass: "right" }, [
            _vm._v("$" + _vm._s(_vm.formatPrice(_vm.subtotal)))
          ])
        ]),
        _c("tr", [
          _c("td", { staticClass: "left" }, [
            _c("strong", [
              _vm._v("GST (" + _vm._s(_vm.invoice.tax_percentage) + "%)")
            ])
          ]),
          _c("td", { staticClass: "right" }, [_vm._v("$" + _vm._s(_vm.tax))])
        ]),
        _c("tr", [
          _vm._m(4),
          _c("td", { staticClass: "right" }, [
            _c("strong", [
              _vm._v(
                "$" +
                  _vm._s(_vm.formatPrice(_vm.invoice.total)) +
                  " " +
                  _vm._s(_vm.currency)
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "left" }, [
      _c("strong", [_vm._v("Subtotal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "left" }, [
      _c("strong", [_vm._v("Quick Pay Subtotal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "left" }, [
      _c("strong", [_vm._v("Quick Pay Total")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "left" }, [
      _c("strong", [_vm._v("Subtotal")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "left" }, [_c("strong", [_vm._v("Total")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }